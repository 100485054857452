<template>
  <menu-icon class="open-icon" :size="38"></menu-icon>
</template>

<script>
import MenuIcon from 'vue-material-design-icons/Menu';

export default {
  components: {MenuIcon},
};
</script>

<style lang="scss" scoped>
.open-icon {
  color: white;
  background: black;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
