<template>
  <div class="template reading-template">
    <open-mobile-menu-icon @click.native="openMenu"></open-mobile-menu-icon>

    <div v-if="isMobile && isMenuShown" class="hidden-overlay" @click="clickHiddenOverlay"></div>
    <transition name="slide">
      <reading-template-menu
        v-show="!isMobile || isMenuShown"
        :is-mobile="isMobile"
        @click-toc="hideMenu"
      ></reading-template-menu>
    </transition>

    <div class="content-container" :class="{'is-mobile': isMobile}">
      <div class="content">
        <slot></slot>
      </div>
      <div class="end-content-image" v-if="showEndContentImage"></div>
    </div>
  </div>
</template>

<script>
import OpenMobileMenuIcon from '@/components/modules/familyWebsite/templates/reading/OpenMobileMenuIcon';
import ReadingTemplateMenu from '@/components/modules/familyWebsite/templates/reading/ReadingTemplateMenu';

export default {
  mounted() {
    const externalLinks = Array.from(document.querySelectorAll('.content a')).filter(
      item => item.classList.length === 0
    );

    externalLinks.forEach(item => {
      item.setAttribute('target', '_blank');
    });

    this.showEndContentImage = Array.from(document.querySelectorAll('.content h2')).length;
  },
  data() {
    return {
      isMenuShown: false,
      showEndContentImage: false,
    };
  },
  computed: {
    isMobile() {
      return this.$store.getters.windowWidthState < this.$breakpoints.desktop;
    },
  },
  methods: {
    openMenu() {
      this.isMenuShown = true;
    },
    hideMenu() {
      this.isMenuShown = false;
    },
    clickHiddenOverlay() {
      this.hideMenu();
    },
  },
  components: {OpenMobileMenuIcon, ReadingTemplateMenu},
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables-reading';
@import '../../styles/base-styles-reading';

.reading-template .content {
  @import '../../styles/glossary';
  @import '../../styles/ancestor';
  @import '../../styles/commentary-reading';
  @import '../../styles/inline-commentary';
  @import '../../styles/feature';
  @import '../../styles/feature-sidebar';
  @import '../../styles/facts';
  @import '../../styles/siblings';
}

.hidden-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.4s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(-200px);
  opacity: 0;
}
</style>
