<template>
  <div class="reading-template-menu" :class="{'is-mobile': isMobile}">
    <sites-menu :is-mobile="isMobile"></sites-menu>
    <table-of-contents @click.native="$emit('click-toc')"></table-of-contents>
  </div>
</template>

<script>
import SitesMenu from '@/components/modules/familyWebsite/templates/SitesMenu';
import TableOfContents from '@/components/modules/familyWebsite/templates/reading/TableOfContents';

export default {
  props: {
    isMobile: Boolean,
  },
  components: {TableOfContents, SitesMenu},
};
</script>

<style lang="scss" scoped>
.reading-template-menu {
  width: var(--report-menu-width-desktop);
  height: 100%;
  left: 0;
  top: 0;
  background-color: var(--report-menu-background);
  flex-shrink: 0;
  overflow: hidden;
  position: fixed;
  z-index: 1;
}

.reading-template-menu.is-mobile {
  position: fixed;
  width: 85%;
}
</style>
